import { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/hook";
import { useForm } from "react-hook-form";
import {
	Box,
	Button,
	Center,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Icon,
	Input,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Radio,
	RadioGroup,
	Stack,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react";
import { validationMsg } from "../../data";
import { FiFile, FiMessageCircle } from "react-icons/fi";
import { hideSMS } from "../../redux/slices/smsSlice";
import smsAPI from "../../apis/sms";

interface FormProps {}

interface FormInputs {
	files: FileList;
	message?: string;
}

enum Forms {
	singleMsg,
	multiMsg,
}

const SendSMS: FC<FormProps> = () => {
	const isActive = useAppSelector((state) => state.sms.isActive);
	const dispatch = useAppDispatch();
	const toast = useToast();

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm<FormInputs>();

	const [isLoading, setIsLoading] = useState(false);

	function onFormSubmit(data: FormInputs) {
		setIsLoading(true);
		smsAPI
			.sendSms(data.files[0], data.message)
			.then((res) => {
				setIsLoading(false);
				closeForm();
				toast({
					description: res.message,
					status: "success",
					duration: 5000,
					isClosable: true,
				});
			})
			.catch(() => setIsLoading(false));
	}

	const [currForm, setCurrForm] = useState(Forms.singleMsg);

	function changeForm(form: Forms) {
		setCurrForm(form);
		reset();
	}

	function closeForm() {
		dispatch(hideSMS());
		reset();
	}

	return (
		<Modal isOpen={isActive} onClose={closeForm} size="2xl">
			<ModalOverlay />
			<ModalContent mx={2}>
				<ModalHeader>إرسال رسالة نصية</ModalHeader>
				<form noValidate onSubmit={handleSubmit(onFormSubmit)}>
					<ModalBody>
						<Center mb={10} justifyContent={{ base: "start", sm: "center" }}>
							<RadioGroup
								onChange={(value) => changeForm(parseInt(value))}
								value={currForm}
							>
								<Stack direction={{ base: "column", sm: "row" }} spacing={4}>
									<Radio value={Forms.singleMsg}>رسالة موحدة لكل العملاء</Radio>
									<Radio value={Forms.multiMsg}>رسالة مخصصة لكل عميل</Radio>
								</Stack>
							</RadioGroup>
						</Center>
						<VStack
							alignItems="start"
							sx={{
								".chakra-form-control input, .chakra-form-control textarea": {
									ps: "6",
								},
								".chakra-form-control .chakra-icon": {
									pos: "absolute",
									top: "40%",
									transform: "translateY(-50%)",
									color: "gray",
								},
							}}
						>
							<FormControl isInvalid={!!errors.files}>
								<FormLabel srOnly>أرقام العملاء</FormLabel>
								<Box pos="relative">
									<Icon as={FiFile} />
									<Input
										type={"file"}
										accept={".xlsx"}
										placeholder="أرقام العملاء"
										title={"ارقام العملاء"}
										{...register("files", {
											required: validationMsg["required"],
											validate: (value) => {
												if (value[0].name.endsWith(".xlsx")) return true;
												return "يجب ان يكون الملف بصيغة xlsx";
											},
										})}
									/>
								</Box>
								<FormErrorMessage>{errors.files?.message}</FormErrorMessage>
							</FormControl>
							{currForm === Forms.singleMsg && (
								<FormControl isInvalid={!!errors.message}>
									<FormLabel srOnly>محتوى الرسالة</FormLabel>
									<Box pos="relative">
										<Icon
											as={FiMessageCircle}
											sx={{ top: "21px !important" }}
										/>
										<Textarea
											placeholder="محتوى الرسالة"
											rows={2}
											{...register("message", {
												required: validationMsg["required"],
											})}
										/>
									</Box>
									<FormErrorMessage>{errors.message?.message}</FormErrorMessage>
								</FormControl>
							)}
						</VStack>
					</ModalBody>
					<ModalFooter>
						<Button me="2" type="submit" isLoading={isLoading}>
							إرسال
						</Button>
						<Button variant="ghost" onClick={closeForm}>
							إلغاء
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};

export default SendSMS;
