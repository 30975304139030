import admins from "./adminsSlice";
import clients from "./clientsSlice";
import roles from "./rolesSlice";
import permissions from "./permissionsSlice";
import auth from "./authSlice";
import employees from "./employeesSlice";
import lang from "./languagesSlice";
import countries from "./countriesSlice";
import cities from "./citiesSlice";
import sms from "./smsSlice";
import invoices from "./invoiceSlice";
import wInstances from "./wInstanceSlice";
import coupons from "./couponSlice";
import packages from "./packageSlice";

const reducers = {
	admins,
	clients,
	roles,
	permissions,
	auth,
	employees,
	lang,
	countries,
	cities,
	sms,
	invoices,
	wInstances,
	coupons,
	packages
};

export default reducers;
