import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from "@reduxjs/toolkit";
import countriesAPI from "../../apis/countries";
import { PendingAction, RejectedAction } from "../types";

export const handleLoadCountries = createAsyncThunk(
	"countries/load",
	(page: number = 1) => countriesAPI.fetchPage(page),
	{
		condition: (page = 1, { getState }: any) => {
			if (getState().countries.currentPage === page) return false;
		},
	}
);

export const handleStoreCountry = createAsyncThunk(
	"countries/store",
	(data: StoreCountry) => countriesAPI.store(data)
);

export const handleUpdateCountry = createAsyncThunk(
	"countries/update",
	({ id, data }: { id: number; data: StoreCountry }) =>
		countriesAPI.update(id, data)
);

const countriesAdapter = createEntityAdapter<Country>();

const slice = createSlice({
	name: "countries",
	initialState: {
		...countriesAdapter.getInitialState(),
		currentPage: null as number | null,
		total: 0,
		isFetching: false,
		isStoring: false,
	},
	reducers: {
		countryAdded: countriesAdapter.addOne,
		countryRemoved: countriesAdapter.removeOne,
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadCountries.fulfilled, (state, action) => {
				state.isFetching = false;
				state.currentPage = action.payload.meta.current_page;
				state.total = action.payload.meta.total;
				countriesAdapter.setAll(state, action.payload.data);
			})
			.addCase(handleLoadCountries.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(handleLoadCountries.rejected, (state) => {
				state.isFetching = false;
			})
			.addCase(handleStoreCountry.fulfilled, (state, action) => {
				state.isStoring = false;
				countriesAdapter.addOne(state, action.payload.data);
			})
			.addCase(handleUpdateCountry.fulfilled, (state, action) => {
				state.isStoring = false;
				countriesAdapter.setOne(state, action.payload.data);
			})
			.addMatcher<PendingAction>(
				(action) => /^countries\/(store|update)\/pending$/.test(action.type),
				(state) => {
					state.isStoring = true;
				}
			)
			.addMatcher<RejectedAction>(
				(action) => /^countries\/(store|update)\/rejected$/.test(action.type),
				(state) => {
					state.isStoring = false;
				}
			);
	},
});

const { countryAdded, countryRemoved } = slice.actions;

export function handleDeleteCountry(country: Country) {
	return (dispatch: any) => {
		dispatch(countryRemoved(country.id));
		return countriesAPI.destroy(country.id).catch(() => {
			dispatch(countryAdded(country));
		});
	};
}

export default slice.reducer;
