import {
	createAsyncThunk,
	createSlice,
	createEntityAdapter,
} from "@reduxjs/toolkit";
import employeesApi from "../../apis/employees";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadEmployees = createAsyncThunk(
	"employees/all",
	employeesApi.fetchPage,
	{
		condition: ({ page, client_id }, { getState }) => {
			const { currentPage, filterBy } = getState().employees;
			return currentPage !== page || filterBy !== client_id;
		},
	}
);

const adapter = createEntityAdapter<Employee>();

const slice = createSlice({
	name: "clients",
	initialState: adapter.getInitialState({
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		filterBy: undefined as number | undefined,
		status: "idle" as LoadingStatus,
	}),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadEmployees.pending, (state) => {
				state.status = "pending";
				adapter.removeAll(state);
			})
			.addCase(handleLoadEmployees.fulfilled, (state, { payload, meta }) => {
				state.status = "succeeded";
				state.currentPage = meta.arg.page;
				state.filterBy = meta.arg.client_id;
				state.total = payload.meta.total;
				state.pagesCount = payload.meta.last_page;
				adapter.addMany(state, payload.data);
			})
			.addMatcher(isRejectedAction("employees/"), (state) => {
				state.status = "failed";
			});
	},
});

export const { selectAll: selectEmployees } = adapter.getSelectors();
export default slice.reducer;
