import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from "@reduxjs/toolkit";
import packagesAPI from "../../apis/packages";
import { PendingAction, RejectedAction } from "../types";

export const handleLoadPackages = createAsyncThunk(
	"packages/load",
	(page: number = 1) => packagesAPI.fetchPage(page),
	{
		condition: (page = 1, { getState }: any) => {
			if (getState().packages.currentPage === page) return false;
		},
	}
);

export const handleShowPackage = createAsyncThunk(
	"packages/show",
	(id: number) => packagesAPI.show(id),
	{
		condition: (id, { getState }: any) => true,
	}
);

export const handleStorePackage = createAsyncThunk(
	"packages/store",
	(data: StorePackage) => packagesAPI.store(data)
);

export const handleUpdatePackage = createAsyncThunk(
	"packages/update",
	({ id, data }: { id: number; data: Partial<StorePackage> }) =>
		packagesAPI.update(id, data)
);

const packageAdapter = createEntityAdapter<Package>();

const slice = createSlice({
	name: "packages",
	initialState: {
		...packageAdapter.getInitialState(),
		currentPage: null as number | null,
		total: 0,
		isFetching: false,
		isStoring: false,
	},
	reducers: {
		packageAdded: packageAdapter.addOne,
		packageRemoved: packageAdapter.removeOne,
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadPackages.fulfilled, (state, action) => {
				state.isFetching = false;
				state.currentPage = action.payload.meta.current_page;
				state.total = action.payload.meta.total;
				packageAdapter.setAll(state, action.payload.data);
			})
			.addCase(handleShowPackage.fulfilled, (state, action) => {
				packageAdapter.setOne(state, action.payload.data);
			})
			.addCase(handleStorePackage.fulfilled, (state, action) => {
				state.isStoring = false;
				packageAdapter.addOne(state, action.payload.data);
			})
			.addCase(handleUpdatePackage.fulfilled, (state, action) => {
				state.isStoring = false;
				packageAdapter.setOne(state, action.payload.data);
			})
			.addMatcher<PendingAction>(
				(action) => /^packages\/(store|update)\/pending$/.test(action.type),
				(state) => {
					state.isStoring = true;
				}
			)
			.addMatcher<RejectedAction>(
				(action) => /^packages\/(store|update)\/rejected$/.test(action.type),
				(state) => {
					state.isStoring = false;
				}
			)
			.addMatcher<PendingAction>(
				(action) => /^packages\/(load)\/pending$/.test(action.type),
				(state) => {
					state.isFetching = true;
				}
			)
			.addMatcher<RejectedAction>(
				(action) => /^packages\/(load)\/rejected$/.test(action.type),
				(state) => {
					state.isFetching = false;
				}
			);
	},
});

const { packageAdded, packageRemoved } = slice.actions;

export function handleDeletePackage(pack: Package) {
	return (dispatch: any) => {
		dispatch(packageRemoved(pack.id));
		return packagesAPI.destroy(pack.id).catch(() => {
			dispatch(packageAdded(pack));
		});
	};
}

export default slice.reducer;
