import {
	Box,
	Button,
	Checkbox,
	Container,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Icon,
	Input,
	VStack,
} from "@chakra-ui/react";
import { FC } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { handleLogin } from "../redux/slices/authSlice";
import { useAppDispatch, useAppSelector } from "../utils/hook";
import { useForm } from "react-hook-form";
// images
import bg from "../images/login-bg.webp";
import { validationMsg } from "../data";
import { emailRegex } from "../utils/helpers";
import { FiLock, FiUser } from "react-icons/fi";

interface Props {}
const Login: FC<Props> = () => {
	const { state } = useLocation<any>();
	const isAuthed = useAppSelector((state) => state.auth.isAuthed);

	if (isAuthed) {
		return <Redirect to={state?.from ? state.from : "/"} />;
	}

	return (
		<Flex
			className="page"
			py="0"
			alignItems="stretch"
			sx={{
				"> div": {
					pos: "relative",
					minH: "100vh",
					flex: "1 1 50%",
				},
			}}
		>
			<Box
				d={{ base: "none", md: "block" }}
				bgImage={`url(${bg})`}
				bgPos="center"
				bgSize="cover"
				bgRepeat="no-repeat"
			/>
			<Flex direction="column" justify="space-evenly">
				<Container>
					<Heading textAlign="center" letterSpacing="widest">
						BARQ
					</Heading>
				</Container>
				<Container maxW="md" mt="5">
					<Heading size="md" mb="5">
						لوحة التحكم
					</Heading>
					<Form />
				</Container>
			</Flex>
		</Flex>
	);
};

const Form: FC = (props) => {
	const dispatch = useAppDispatch();
	const isLoading = useAppSelector((state) => state.auth.isLoading);
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<LoginData>();

	function onSubmit(data: LoginData) {
		dispatch(handleLogin(data));
	}

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			noValidate
			style={{ textAlign: "center" }}
		>
			<VStack
				alignItems="start"
				mb="5"
				sx={{
					".chakra-form-control input": {
						ps: "6",
					},
					".chakra-form-control .chakra-icon": {
						pos: "absolute",
						top: "50%",
						transform: "translateY(-50%)",
					},
				}}
			>
				<FormControl isInvalid={!!errors.email}>
					<FormLabel srOnly>Email Address</FormLabel>
					<Box pos="relative">
						<Icon as={FiUser} />
						<Input
							type="email"
							placeholder="البريد الإلكتروني"
							{...register("email", {
								required: validationMsg["required"],
								pattern: {
									value: emailRegex,
									message: validationMsg["invalidEmail"],
								},
							})}
							autoFocus
						/>
					</Box>
					<FormErrorMessage>{errors.email?.message}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={!!errors.password}>
					<FormLabel srOnly>Password</FormLabel>
					<Box pos="relative">
						<Icon as={FiLock} />
						<Input
							type="password"
							placeholder="كلمة المرور"
							{...register("password", {
								required: validationMsg["required"],
							})}
						/>
					</Box>
					<FormErrorMessage>{errors.password?.message}</FormErrorMessage>
				</FormControl>
				<Checkbox>تذكرني</Checkbox>
			</VStack>
			<Button
				isLoading={isLoading}
				type="submit"
				minW="80%"
				borderRadius="full"
			>
				تسجيل الدخول
			</Button>
		</form>
	);
};

export default Login;
