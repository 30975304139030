import { client } from ".";

async function fetchPage(page: number) {
	const { data } = await client.get<ResultList<Invoice>>(`/invoices`, {
		params: { page },
	});
	return data;
}

async function show(id: number) {
	const { data } = await client.get<Result<Invoice>>(`/invoices/${id}`);
	return data;
}

const invoicesAPI = {
	fetchPage,
	show,
};

export default invoicesAPI;
