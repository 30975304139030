import { client } from ".";

async function fetchPage(page: number) {
	const { data } = await client.get<ResultList<City>>(`/cities`, {
		params: { page },
	});
	return data;
}

async function store(item: StoreCity) {
	const { data } = await client.post<Result<City>>("/cities", item);
	return data;
}

async function update(id: number, item: StoreCity) {
	const { data } = await client.put<Result<City>>(`/cities/${id}`, item);
	return data;
}

async function destroy(id: number) {
	const { data } = await client.delete(`/cities/${id}`);
	return data;
}

const citiesAPI = {
	fetchPage,
	store,
	update,
	destroy,
};

export default citiesAPI;
