import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from "@reduxjs/toolkit";
import permissionsAPI from "../../apis/permissions";

export const handleLoadPermissions = createAsyncThunk(
	"permissions/load",
	(page: number = 1) => permissionsAPI.fetchPage(page),
	{
		condition: (page = 1, { getState }: any) => {
			if (getState().permissions.currentPage === page) return false;
		},
	}
);

const permissionsAdapter = createEntityAdapter<Permission>();

const slice = createSlice({
	name: "permissions",
	initialState: {
		...permissionsAdapter.getInitialState(),
		currentPage: null as number | null,
		total: 0,
		isFetching: false,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadPermissions.fulfilled, (state, action) => {
				state.isFetching = false;
				state.currentPage = action.payload.meta.current_page;
				state.total = action.payload.meta.total;
				permissionsAdapter.setAll(state, action.payload.data);
			})
			.addCase(handleLoadPermissions.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(handleLoadPermissions.rejected, (state) => {
				state.isFetching = false;
			});
	},
});

export default slice.reducer;
