import { client } from ".";

async function fetchPage(page: number) {
	const { data } = await client.get<ResultList<Package>>(`/packages`, {
		params: { page },
	});
	return data;
}

async function show(id: number) {
	const { data } = await client.get<Result<Package>>(`/packages/${id}`);
	return data;
}

async function store(item: StorePackage) {
	const { data } = await client.post<Result<Package>>("/packages", item);
	return data;
}

async function update(id: number, item: Partial<StorePackage>) {
	const { data } = await client.put<Result<Package>>(`/packages/${id}`, item);
	return data;
}

async function destroy(id: number) {
	const { data } = await client.delete(`/packages/${id}`);
	return data;
}

const packagesAPI = {
	fetchPage,
	show,
	store,
	update,
	destroy,
};

export default packagesAPI;
