import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from "@reduxjs/toolkit";
import langAPI from "../../apis/languages";
import { PendingAction, RejectedAction } from "../types";

export const handleLoadLanguages = createAsyncThunk(
	"lang/load",
	(page: number = 1) => langAPI.fetchPage(page),
	{
		condition: (page = 1, { getState }: any) => {
			if (getState().lang.currentPage === page) return false;
		},
	}
);

export const handleStoreLang = createAsyncThunk(
	"lang/store",
	(data: StoreLang) => langAPI.store(data)
);

export const handleUpdateLang = createAsyncThunk(
	"lang/update",
	({ id, data }: { id: number; data: StoreLang }) => langAPI.update(id, data)
);

const langAdapter = createEntityAdapter<Lang>();

const slice = createSlice({
	name: "lang",
	initialState: {
		...langAdapter.getInitialState(),
		currentPage: null as number | null,
		total: 0,
		isFetching: false,
		isStoring: false,
	},
	reducers: {
		langAdded: langAdapter.addOne,
		langRemoved: langAdapter.removeOne,
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadLanguages.fulfilled, (state, action) => {
				state.isFetching = false;
				state.currentPage = action.payload.meta.current_page;
				state.total = action.payload.meta.total;
				langAdapter.setAll(state, action.payload.data);
			})
			.addCase(handleLoadLanguages.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(handleLoadLanguages.rejected, (state) => {
				state.isFetching = false;
			})
			.addCase(handleStoreLang.fulfilled, (state, action) => {
				state.isStoring = false;
				langAdapter.addOne(state, action.payload.data);
			})
			.addCase(handleUpdateLang.fulfilled, (state, action) => {
				state.isStoring = false;
				langAdapter.setOne(state, action.payload.data);
			})
			.addMatcher<PendingAction>(
				(action) => /^lang\/(store|update)\/pending$/.test(action.type),
				(state) => {
					state.isStoring = true;
				}
			)
			.addMatcher<RejectedAction>(
				(action) => /^lang\/(store|update)\/rejected$/.test(action.type),
				(state) => {
					state.isStoring = false;
				}
			);
	},
});

const { langAdded, langRemoved } = slice.actions;

export function handleDeleteLang(lang: Lang) {
	return (dispatch: any) => {
		dispatch(langRemoved(lang.id));
		return langAPI.destroy(lang.id).catch(() => {
			dispatch(langAdded(lang));
		});
	};
}

export default slice.reducer;
