import { client } from ".";

async function login(credentials: LoginData) {
	const { data } = await client.post<Result<Admin> & { token: string }>(
		"/login",
		credentials
	);
	return data;
}

async function logout() {
	const { data } = await client.post<{ message: string }>("/logout");
	return data;
}

const authAPI = {
	login,
	logout,
};

export default authAPI;
