import { client } from ".";

async function fetchPage(page: number) {
	const { data } = await client.get<ResultList<Role>>(`/roles`, {
		params: { page },
	});
	return data;
}

async function store(item: StoreRole) {
	const { data } = await client.post<Result<Role>>("/roles", item);
	return data;
}

async function update(id: number, item: StoreRole) {
	const { data } = await client.put<Result<Role>>(`/roles/${id}`, item);
	return data;
}

async function destroy(id: number) {
	const { data } = await client.delete(`/roles/${id}`);
	return data;
}

const rolesAPI = {
	fetchPage,
	store,
	update,
	destroy,
};

export default rolesAPI;
