import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "sms",
	initialState: { isActive: false },
	reducers: {
		showSMS: (state) => ({ isActive: true }),
		hideSMS: (state) => ({ isActive: false }),
	},
});

export const { showSMS, hideSMS } = slice.actions;

export default slice.reducer;
