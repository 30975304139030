import { client } from ".";

async function sendSms(file: File, message?: string) {
	let api = "/send-different-sms-to-bulk-phones";
	const formData = new FormData();
	formData.append("file", file);
	// handle sending the same message to all recipients
	if (message) {
		api = "/send-same-sms-to-bulk-phones";
		formData.append("message", message);
	}
	const { data } = await client.post(api, formData);
	return data;
}

const smsAPI = {
	sendSms,
};

export default smsAPI;
