import React, { FC } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAppSelector } from "../utils/hook";

const PrivateRoute: FC<RouteProps> = ({ children, ...rest }) => {
	const isAuthed = useAppSelector((state) => state.auth.isAuthed);
	if (!isAuthed) {
		return (
			<Redirect
				to={{
					pathname: "/login",
					state: { from: window.location.pathname },
				}}
			/>
		);
	}

	return <Route {...rest}>{children}</Route>;
};

export default PrivateRoute;
