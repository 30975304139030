import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from "@reduxjs/toolkit";
import rolesAPI from "../../apis/roles";
import { PendingAction, RejectedAction } from "../types";

export const handleLoadRoles = createAsyncThunk(
	"roles/load",
	(page: number = 1) => rolesAPI.fetchPage(page),
	{
		condition: (page = 1, { getState }: any) => {
			if (getState().roles.currentPage === page) return false;
		},
	}
);

export const handleStoreRole = createAsyncThunk(
	"roles/store",
	(data: StoreRole) => rolesAPI.store(data)
);

export const handleUpdateRole = createAsyncThunk(
	"roles/update",
	({ id, data }: { id: number; data: StoreRole }) => rolesAPI.update(id, data)
);

const rolesAdapter = createEntityAdapter<Role>();

const slice = createSlice({
	name: "roles",
	initialState: {
		...rolesAdapter.getInitialState(),
		currentPage: null as number | null,
		total: 0,
		isFetching: false,
		isStoring: false,
	},
	reducers: {
		roleAdded: rolesAdapter.addOne,
		roleRemoved: rolesAdapter.removeOne,
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadRoles.fulfilled, (state, action) => {
				state.isFetching = false;
				state.currentPage = action.payload.meta.current_page;
				state.total = action.payload.meta.total;
				rolesAdapter.setAll(state, action.payload.data);
			})
			.addCase(handleStoreRole.fulfilled, (state, action) => {
				state.isStoring = false;
				rolesAdapter.addOne(state, action.payload.data);
			})
			.addCase(handleUpdateRole.fulfilled, (state, action) => {
				state.isStoring = false;
				rolesAdapter.setOne(state, action.payload.data);
			})
			.addMatcher<PendingAction>(
				(action) => /^roles\/(store|update)\/pending$/.test(action.type),
				(state) => {
					state.isStoring = true;
				}
			)
			.addMatcher<RejectedAction>(
				(action) => /^roles\/(store|update)\/rejected$/.test(action.type),
				(state) => {
					state.isStoring = false;
				}
			)
			.addMatcher<PendingAction>(
				(action) => /^roles\/(load)\/pending$/.test(action.type),
				(state) => {
					state.isFetching = true;
				}
			)
			.addMatcher<RejectedAction>(
				(action) => /^roles\/(load)\/rejected$/.test(action.type),
				(state) => {
					state.isFetching = false;
				}
			);
	},
});

const { roleAdded, roleRemoved } = slice.actions;

export function handleDeleteRole(role: Role) {
	return (dispatch: any) => {
		dispatch(roleRemoved(role.id));
		return rolesAPI.destroy(role.id).catch(() => {
			dispatch(roleAdded(role));
		});
	};
}

export default slice.reducer;
