import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from "@reduxjs/toolkit";
import citiesAPI from "../../apis/cities";
import { PendingAction, RejectedAction } from "../types";

export const handleLoadCities = createAsyncThunk(
	"cities/load",
	(page: number = 1) => citiesAPI.fetchPage(page),
	{
		condition: (page = 1, { getState }: any) => {
			if (getState().cities.currentPage === page) return false;
		},
	}
);

export const handleStoreCity = createAsyncThunk(
	"cities/store",
	(data: StoreCity) => citiesAPI.store(data)
);

export const handleUpdateCity = createAsyncThunk(
	"cities/update",
	({ id, data }: { id: number; data: StoreCity }) => citiesAPI.update(id, data)
);

const citiesAdapter = createEntityAdapter<City>();

const slice = createSlice({
	name: "cities",
	initialState: {
		...citiesAdapter.getInitialState(),
		currentPage: null as number | null,
		total: 0,
		isFetching: false,
		isStoring: false,
	},
	reducers: {
		cityAdded: citiesAdapter.addOne,
		cityRemoved: citiesAdapter.removeOne,
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadCities.fulfilled, (state, action) => {
				state.isFetching = false;
				state.currentPage = action.payload.meta.current_page;
				state.total = action.payload.meta.total;
				citiesAdapter.setAll(state, action.payload.data);
			})
			.addCase(handleLoadCities.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(handleLoadCities.rejected, (state) => {
				state.isFetching = false;
			})
			.addCase(handleStoreCity.fulfilled, (state, action) => {
				state.isStoring = false;
				citiesAdapter.addOne(state, action.payload.data);
			})
			.addCase(handleUpdateCity.fulfilled, (state, action) => {
				state.isStoring = false;
				citiesAdapter.setOne(state, action.payload.data);
			})
			.addMatcher<PendingAction>(
				(action) => /^cities\/(store|update)\/pending$/.test(action.type),
				(state) => {
					state.isStoring = true;
				}
			)
			.addMatcher<RejectedAction>(
				(action) => /^cities\/(store|update)\/rejected$/.test(action.type),
				(state) => {
					state.isStoring = false;
				}
			);
	},
});

const { cityAdded, cityRemoved } = slice.actions;

export function handleDeleteCity(city: City) {
	return (dispatch: any) => {
		dispatch(cityRemoved(city.id));
		return citiesAPI.destroy(city.id).catch(() => {
			dispatch(cityAdded(city));
		});
	};
}

export default slice.reducer;
