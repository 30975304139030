import { client } from ".";

async function fetchPage(page: number) {
	const { data } = await client.get<ResultList<Permission>>(`/permissions`, {
		params: { page },
	});
	return data;
}

const permissionsAPI = {
	fetchPage,
};

export default permissionsAPI;
