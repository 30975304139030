import { ThemeOverride } from "@chakra-ui/react";

const styles: ThemeOverride["styles"] = {
	global: {
		body: {
			bgColor: "gray.50",
		},
		".page": {
			minH: "100vh",
			py: "10",
		},
		".chakra-switch": {pos: "relative"}
	},
};

export default styles;
