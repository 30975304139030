import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from "@reduxjs/toolkit";
import adminsAPI from "../../apis/admins";
import { PendingAction, RejectedAction } from "../types";

export const handleLoadAdmins = createAsyncThunk(
	"admins/load",
	(page: number = 1) => adminsAPI.fetchPage(page),
	{
		condition: (page = 1, { getState }: any) => {
			if (getState().admins.currentPage === page) return false;
		},
	}
);

export const handleShowAdmin = createAsyncThunk(
	"admins/show",
	(id: number) => adminsAPI.show(id),
	{
		condition: (id, { getState }: any) => true,
	}
);

export const handleStoreAdmin = createAsyncThunk(
	"admins/store",
	(data: StoreAdmin) => adminsAPI.store(data)
);

export const handleUpdateAdmin = createAsyncThunk(
	"admins/update",
	({ id, data }: { id: number; data: StoreAdmin }) => adminsAPI.update(id, data)
);

const adminsAdapter = createEntityAdapter<Admin>();

const slice = createSlice({
	name: "admins",
	initialState: {
		...adminsAdapter.getInitialState(),
		currentPage: null as number | null,
		total: 0,
		isFetching: false,
		isStoring: false,
	},
	reducers: {
		adminAdded: adminsAdapter.addOne,
		adminRemoved: adminsAdapter.removeOne,
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadAdmins.fulfilled, (state, action) => {
				state.isFetching = false;
				state.currentPage = action.payload.meta.current_page;
				state.total = action.payload.meta.total;
				adminsAdapter.setAll(state, action.payload.data);
			})
			.addCase(handleShowAdmin.fulfilled, (state, action) => {
				adminsAdapter.setOne(state, action.payload.data);
			})
			.addCase(handleStoreAdmin.fulfilled, (state, action) => {
				state.isStoring = false;
				adminsAdapter.addOne(state, action.payload.data);
			})
			.addCase(handleUpdateAdmin.fulfilled, (state, action) => {
				state.isStoring = false;
				adminsAdapter.setOne(state, action.payload.data);
			})
			.addMatcher<PendingAction>(
				(action) => /^admins\/(store|update)\/pending$/.test(action.type),
				(state) => {
					state.isStoring = true;
				}
			)
			.addMatcher<RejectedAction>(
				(action) => /^admins\/(store|update)\/rejected$/.test(action.type),
				(state) => {
					state.isStoring = false;
				}
			)
			.addMatcher<PendingAction>(
				(action) => /^admins\/(load)\/pending$/.test(action.type),
				(state) => {
					state.isFetching = true;
				}
			)
			.addMatcher<RejectedAction>(
				(action) => /^admins\/(load)\/rejected$/.test(action.type),
				(state) => {
					state.isFetching = false;
				}
			);
	},
});

const { adminAdded, adminRemoved } = slice.actions;

export function handleDeleteAdmin(admin: Admin) {
	return (dispatch: any) => {
		dispatch(adminRemoved(admin.id));
		return adminsAPI.destroy(admin.id).catch(() => {
			dispatch(adminAdded(admin));
		});
	};
}

export default slice.reducer;
