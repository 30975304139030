import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from "@reduxjs/toolkit";
import couponsAPI from "../../apis/coupons";
import { PendingAction, RejectedAction } from "../types";
import clientsAPI from "../../apis/clients";
import { handleUpdateClientStatus } from "./clientsSlice";

export const handleLoadCoupons = createAsyncThunk(
	"coupons/load",
	(page: number = 1) => couponsAPI.fetchPage(page),
	{
		condition: (page = 1, { getState }: any) => {
			if (getState().coupons.currentPage === page) return false;
		},
	}
);

export const handleShowCoupon = createAsyncThunk(
	"coupons/show",
	(id: number) => couponsAPI.show(id),
	{
		condition: (id, { getState }: any) => true,
	}
);

export const handleStoreCoupon = createAsyncThunk(
	"coupons/store",
	(data: StoreCoupon) => couponsAPI.store(data)
);

export const handleUpdateCoupon = createAsyncThunk(
	"coupons/update",
	({ id, data }: { id: number; data: UpdateCoupon }) =>
		couponsAPI.update(id, data)
);

export const handleUpdateCouponStatus = createAsyncThunk(
	"coupons/update-status",
	({ id, data }: { id: number; data: { is_active: 0 | 1 } }) =>
		couponsAPI.update(id, data)
);

const couponsAdapter = createEntityAdapter<Coupon>();

const slice = createSlice({
	name: "coupons",
	initialState: {
		...couponsAdapter.getInitialState(),
		currentPage: null as number | null,
		total: 0,
		isFetching: false,
		isStoring: false,
	},
	reducers: {
		couponAdded: couponsAdapter.addOne,
		couponRemoved: couponsAdapter.removeOne,
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadCoupons.fulfilled, (state, action) => {
				state.isFetching = false;
				state.currentPage = action.payload.meta.current_page;
				state.total = action.payload.meta.total;
				couponsAdapter.setAll(state, action.payload.data);
			})
			.addCase(handleShowCoupon.fulfilled, (state, action) => {
				couponsAdapter.setOne(state, action.payload.data);
			})
			.addCase(handleStoreCoupon.fulfilled, (state, action) => {
				state.isStoring = false;
				couponsAdapter.addOne(state, action.payload.data);
			})
			.addCase(handleUpdateCoupon.fulfilled, (state, action) => {
				state.isStoring = false;
				couponsAdapter.setOne(state, action.payload.data);
			})
			.addCase(handleUpdateCouponStatus.pending, (state, { meta }) => {
				const {
					id,
					data: { is_active },
				} = meta.arg;
				couponsAdapter.updateOne(state, { id, changes: { is_active } });
			})
			.addCase(handleUpdateCouponStatus.rejected, (state, { meta }) => {
				const {
					id,
					data: { is_active },
				} = meta.arg;
				couponsAdapter.updateOne(state, {
					id,
					changes: { is_active: is_active === 1 ? 0 : 1 },
				});
			})
			.addMatcher<PendingAction>(
				(action) => /^coupons\/(store|update)\/pending$/.test(action.type),
				(state) => {
					state.isStoring = true;
				}
			)
			.addMatcher<RejectedAction>(
				(action) => /^coupons\/(store|update)\/rejected$/.test(action.type),
				(state) => {
					state.isStoring = false;
				}
			)
			.addMatcher<PendingAction>(
				(action) => /^coupons\/(load)\/pending$/.test(action.type),
				(state) => {
					state.isFetching = true;
				}
			)
			.addMatcher<RejectedAction>(
				(action) => /^coupons\/(load)\/rejected$/.test(action.type),
				(state) => {
					state.isFetching = false;
				}
			);
	},
});

const { couponAdded, couponRemoved } = slice.actions;

export function handleDeleteCoupon(coupon: Coupon) {
	return (dispatch: any) => {
		dispatch(couponRemoved(coupon.id));
		return couponsAPI.destroy(coupon.id).catch(() => {
			dispatch(couponAdded(coupon));
		});
	};
}

export default slice.reducer;
