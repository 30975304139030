import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/privateRoute";
import Dashboard from "./Dashboard";
import Login from "./pages/login";

function App() {
	return (
		<div className="App">
			<Switch>
				<Route path="/login">
					<Login />
				</Route>
				<PrivateRoute path="/">
					<Dashboard />
				</PrivateRoute>
			</Switch>
		</div>
	);
}

export default App;
