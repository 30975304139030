// using chakra convention for customizing its theme
// ref: https://chakra-ui.com/docs/theming/customize-theme#scaling-out-your-project

import {
	extendTheme,
	ThemeOverride,
	withDefaultColorScheme,
} from "@chakra-ui/react";
import styles from "./styles";
import components from "./components";

const overrides: ThemeOverride = {
	direction: "rtl",
	styles,
	components,
	fonts: {
		heading: "Bahij TheSansArabic, Arial",
		body: "Droid Arabic Kufi, Arial",
	},
};

export default extendTheme(
	overrides,
	withDefaultColorScheme({ colorScheme: "blue" })
);
