import {
	IconButton,
	Box,
	CloseButton,
	Flex,
	Icon,
	useColorModeValue,
	Link,
	Drawer,
	DrawerContent,
	useDisclosure,
	BoxProps,
	FlexProps,
	Heading,
	LinkProps,
	Button,
	useBreakpointValue,
} from "@chakra-ui/react";
import {
	FiTrendingUp,
	FiUsers,
	FiKey,
	FiMenu,
	FiLogOut,
	FiGlobe,
	FiFlag,
	FiMessageCircle,
} from "react-icons/fi";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { FaRegMoneyBillAlt, FaRegBuilding } from "react-icons/fa";
import { RiCoupon2Line, RiStackLine } from "react-icons/ri";

import { NavLink } from "react-router-dom";
import { IconType } from "react-icons";
import { FC } from "react";
import { useAppDispatch } from "../utils/hook";
import { handleLogout } from "../redux/slices/authSlice";
import { showSMS } from "../redux/slices/smsSlice";

interface LinkItemProps {
	name: string;
	to: string;
	exact?: boolean;
	icon: IconType;
}

const LinkItems: Array<LinkItemProps> = [
	{ name: "العملاء", icon: FiTrendingUp, to: "/clients" },
	{ name: "الأجهزة", icon: AiOutlineWhatsApp, to: "/whatsapp-instances" },
	{ name: "أكواد الخصم", icon: RiCoupon2Line, to: "/coupons" },
	{ name: "الفواتير", icon: FaRegMoneyBillAlt, to: "/invoices" },
	{ name: "الباقات", icon: RiStackLine, to: "/packages" },
	{ name: "مشرفي الموقع", icon: FiUsers, to: "/admins" },
	{ name: "الأدوار", icon: FiKey, to: "/roles" },
	{ name: "اللغات", icon: FiGlobe, to: "/languages" },
	{ name: "الدول", icon: FiFlag, to: "/countries" },
	{ name: "المدن", icon: FaRegBuilding, to: "/cities" },
];

const sidebarVrWidth = 64;
const SimpleSidebar: FC<BoxProps> = (props) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const shouldOpenDrawer = useBreakpointValue({
		base: true,
		md: false,
	}) as boolean;

	return (
		<>
			<SidebarContent
				onClose={() => onClose}
				display={{ base: "none", md: "flex" }}
			/>
			<Drawer
				autoFocus={false}
				isOpen={shouldOpenDrawer && isOpen}
				placement="right"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
				size="md"
			>
				<DrawerContent>
					<SidebarContent onClose={onClose} />
				</DrawerContent>
			</Drawer>
			<MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
		</>
	);
};

interface SidebarProps extends BoxProps {
	onClose: () => void;
}

export const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
	const dispatch = useAppDispatch();

	return (
		<Box
			bg={useColorModeValue("white", "gray.900")}
			borderLeft="1px solid"
			borderLeftColor={useColorModeValue("gray.200", "gray.700")}
			boxShadow="lg"
			w={{ base: "full", md: sidebarVrWidth }}
			pos="fixed"
			zIndex="10"
			h="full"
			overflowY="auto"
			sx={{
				"button, link": {},
			}}
			display="flex"
			flexDir={"column"}
			{...rest}
		>
			<Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
				<Heading letterSpacing="widest" size="lg">
					BARQ
				</Heading>
				<CloseButton display={{ md: "none" }} onClick={onClose} />
			</Flex>
			{LinkItems.map((link) => (
				<NavItem
					key={link.name}
					icon={link.icon}
					to={link.to}
					exact={link.exact}
				>
					{link.name}
				</NavItem>
			))}
			<Button
				variant="link"
				iconSpacing="4"
				onClick={() => dispatch(showSMS())}
				pos="relative"
				p="4"
				mx="4"
				lineHeight="base"
				justifyContent={"start"}
				borderRadius="lg"
				color="inherit"
				_hover={{ textDecor: "none" }}
				leftIcon={
					<Icon
						fontSize="16"
						_groupHover={{
							color: "white",
						}}
						as={FiMessageCircle}
					/>
				}
			>
				إرسال SMS
			</Button>
			<Button
				variant="link"
				iconSpacing="4"
				onClick={() => dispatch(handleLogout())}
				pos="relative"
				p="4"
				mx="4"
				lineHeight="base"
				justifyContent={"start"}
				borderRadius="lg"
				color="inherit"
				_hover={{ textDecor: "none" }}
				leftIcon={
					<Icon
						fontSize="16"
						_groupHover={{
							color: "white",
						}}
						as={FiLogOut}
					/>
				}
			>
				تسجيل الخروج
			</Button>
		</Box>
	);
};

type NavItemProps = LinkProps & Omit<LinkItemProps, "name">;
const NavItem = ({ icon, children, to, exact, ...rest }: NavItemProps) => {
	return (
		<Link
			as={NavLink}
			to={to}
			exact={exact}
			d="flex"
			alignItems="center"
			p="4"
			mx="4"
			borderRadius="lg"
			cursor="pointer"
			_hover={{
				textDecor: "none",
			}}
			sx={{
				"&.active": {
					bg: "blue.500",
					color: "white",
				},
			}}
		>
			<Icon
				me="4"
				fontSize="16"
				_groupHover={{
					color: "white",
				}}
				as={icon}
			/>
			{children}
		</Link>
	);
};

interface MobileProps extends FlexProps {
	onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
	return (
		<Flex
			px="4"
			height="20"
			alignItems="center"
			bg={useColorModeValue("white", "gray.900")}
			borderBottomWidth="1px"
			borderBottomColor={useColorModeValue("gray.200", "gray.700")}
			justifyContent="flex-start"
			{...rest}
		>
			<IconButton
				borderRadius="md"
				variant="outline"
				onClick={onOpen}
				aria-label="open menu"
				icon={<FiMenu />}
			/>

			<Heading size="lg" ms="5" letterSpacing="widest">
				BARQ
			</Heading>
		</Flex>
	);
};

export default SimpleSidebar;
