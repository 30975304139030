import { Box, Container, Heading, Spinner } from "@chakra-ui/react";
import { FC, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SimpleSidebar from "./components/sidebar";
import SendSMS from "./components/forms/SendSMS";

const Clients = lazy(() => import("./pages/clients"));
const Admins = lazy(() => import("./pages/admins"));
const Roles = lazy(() => import("./pages/roles"));
const Lang = lazy(() => import("./pages/Lang"));
const Countries = lazy(() => import("./pages/Countries"));
const Cities = lazy(() => import("./pages/Cities"));
const WInstances = lazy(() => import("./pages/wInstances"));
const Invoices = lazy(() => import("./pages/Invoices"));
const Packages = lazy(() => import("./pages/packages"));
const Coupons = lazy(() => import("./pages/Coupons"));

const Dashboard: FC = () => {
	return (
		<>
			<SendSMS />
			<SimpleSidebar />
			<Box ms={{ md: 64 }}>
				<Suspense
					fallback={
						<Box className="page" display="flex" justifyContent="center">
							<Spinner
								size="xl"
								thickness="4px"
								speed="0.65s"
								emptyColor="gray.200"
								color="blue.500"
							/>
						</Box>
					}
				>
					<Switch>
						<Route path="/clients">
							<Clients />
						</Route>
						<Route path="/whatsapp-instances">
							<WInstances />
						</Route>
						<Route path="/admins">
							<Admins />
						</Route>
						<Route path="/roles">
							<Roles />
						</Route>
						<Route path="/languages">
							<Lang />
						</Route>
						<Route path="/countries">
							<Countries />
						</Route>
						<Route path="/cities">
							<Cities />
						</Route>
						<Route path="/invoices">
							<Invoices />
						</Route>
						<Route path="/packages">
							<Packages />
						</Route>
						<Route path="/coupons">
							<Coupons />
						</Route>
						<Route path="/404">
							<Container>
								<Heading textAlign="center" py="5">
									لا يوجد محتوى
								</Heading>
							</Container>
						</Route>
						<Redirect from="/" to="/clients" exact />
						<Redirect to="/404" />
					</Switch>
				</Suspense>
			</Box>
		</>
	);
};

export default Dashboard;
