import { client } from ".";

async function fetchPage(page: number) {
	const { data } = await client.get<ResultList<Client>>(`/clients`, {
		params: { page },
	});
	return data;
}

async function show(id: number) {
	const { data } = await client.get<Result<Client>>(`/clients/${id}`);
	return data;
}

async function store(item: StoreClient) {
	const { data } = await client.post<Result<Client>>("/clients", item);
	return data;
}

async function update(id: number, item: StoreClient) {
	const { data } = await client.put<Result<Client>>(`/clients/${id}`, item);
	return data;
}

async function destroy(id: number) {
	const { data } = await client.delete(`/clients/${id}`);
	return data;
}

type UpdateStatusArg = { id: number; status: Status };
export async function updateStatus({ id, status }: UpdateStatusArg) {
	const { data } = await client.put(`/clients/${id}/update-status`, {
		status,
	});
	return data;
}

const clientsAPI = {
	fetchPage,
	show,
	store,
	update,
	updateStatus,
	destroy,
};

export default clientsAPI;
