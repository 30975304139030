import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from "@reduxjs/toolkit";
import invoicesAPI from "../../apis/invoices";
import { PendingAction, RejectedAction } from "../types";

export const handleLoadInvoices = createAsyncThunk(
	"invoices/load",
	(page: number = 1) => invoicesAPI.fetchPage(page),
	{
		condition: (page = 1, { getState }: any) => {
			if (getState().invoices.currentPage === page) return false;
		},
	}
);

export const handleShowInvoice = createAsyncThunk(
	"invoices/show",
	(id: number) => invoicesAPI.show(id),
	{
		condition: (id, { getState }: any) => true,
	}
);

const invoicesAdapter = createEntityAdapter<Invoice>();

const slice = createSlice({
	name: "invoices",
	initialState: {
		...invoicesAdapter.getInitialState(),
		currentPage: null as number | null,
		total: 0,
		isFetching: false,
		isStoring: false,
	},
	reducers: {
		invoiceAdded: invoicesAdapter.addOne,
		invoiceRemoved: invoicesAdapter.removeOne,
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadInvoices.fulfilled, (state, action) => {
				state.isFetching = false;
				state.currentPage = action.payload.meta.current_page;
				state.total = action.payload.meta.total;
				invoicesAdapter.setAll(state, action.payload.data);
			})
			.addCase(handleShowInvoice.fulfilled, (state, action) => {
				invoicesAdapter.setOne(state, action.payload.data);
			})
			.addMatcher<PendingAction>(
				(action) => /^invoices\/(load)\/pending$/.test(action.type),
				(state) => {
					state.isFetching = true;
				}
			)
			.addMatcher<RejectedAction>(
				(action) => /^invoices\/(load)\/rejected$/.test(action.type),
				(state) => {
					state.isFetching = false;
				}
			);
	},
});

export default slice.reducer;
