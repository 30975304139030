import { client } from ".";

type FetchPageArg = { page: number; client_id?: number };
async function fetchPage(params: FetchPageArg) {
	const { data } = await client.get<ResultList<Employee>>("/employees", {
		params,
	});
	return data;
}

const employeesAPI = {
	fetchPage,
};

export default employeesAPI;
