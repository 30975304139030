import Container from "./container";
import Button from "./button";
import Input from "./input";
import Textarea from "./textarea";

const components = {
	Container,
	Button,
	Input,
	Textarea,
};

export default components;
