import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import authAPI from "../../apis/auth";

export const handleLogin = createAsyncThunk(
	"auth/login",
	async (data: LoginData) => {
		const resData = await authAPI.login(data);
		localStorage.setItem("token", resData.token);
		return resData;
	}
);

const slice = createSlice({
	name: "auth",
	initialState: {
		isLoading: false,
		isAuthed: !!localStorage.getItem("token"),
		token: null as string | null,
	},
	reducers: {
		logout(state) {
			state.isAuthed = false;
			state.token = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleLogin.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isAuthed = true;
				state.token = action.payload.token;
			})
			.addCase(handleLogin.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(handleLogin.rejected, (state, action) => {
				state.isLoading = false;
			})
			.addDefaultCase((state, action) => {
				if (action.error?.message === "401") {
					state.isAuthed = false;
					state.token = null;
				}
			});
	},
});

const { logout } = slice.actions;
export function handleLogout() {
	return async (dispatch: AppDispatch) => {
		dispatch(logout());
		const data = await authAPI.logout();
		localStorage.removeItem("token");
		return data;
	};
}

export default slice.reducer;
