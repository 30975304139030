import { useMemo } from "react";
import {
	shallowEqual,
	TypedUseSelectorHook,
	useDispatch,
	useSelector,
} from "react-redux";
import { useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "../redux";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useShallowEqSelector: typeof useAppSelector = (selector) =>
	useAppSelector(selector, shallowEqual);

export const useSearchParams = () => {
	const { search } = useLocation();
	return useMemo(() => {
		return new URLSearchParams(search);
	}, [search]);
};

export function useCurrentPage(defaultVal = 1) {
	const pageParam = useSearchParams().get("page");
	return pageParam ? parseInt(pageParam) : defaultVal;
}
